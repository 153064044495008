import 'babel-polyfill'
import Chart from 'chart.js';
import Vue from 'vue/dist/vue.js';
import VueI18n from 'vue-i18n';
import pieDefaults from './chart_pie_defaults.js';
import translation from '../messages';
import mixin from './tooltip_mixins';

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: current_language,
    messages: translation,
})

if(document.getElementById("chart_WINNERS_CONCENTRATION")) {
    var app = new Vue({
        i18n,
        mixins: [mixin],
        delimiters: ['[[', ']]'],
        el: '#chart_WINNERS_CONCENTRATION_app',
        data: {
            defaults: pieDefaults,
            chartData: null,
        },
        methods: {
            createChart(chartId) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: this.chartData,
                    options: this.defaults.options,
                });
            },
        },
        mounted: function() {
            this.chartData = winnersConcentrationData;
            this.defaults.options.tooltips.callbacks = {};
            this.defaults.options.tooltips.callbacks.title = this.tooltipTitle;
            this.defaults.options.tooltips.callbacks.label = this.tooltipLabel;
            this.createChart('chart_WINNERS_CONCENTRATION');
        }
    })
}

if(document.getElementById("popup_WINNERS_CONCENTRATION_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#popup_WINNERS_CONCENTRATION_app',
        data: {
            showRawData: false,
        },
        methods: {
            toggleRawData: function() {
                this.showRawData = !this.showRawData;
            }
        }
    })
}