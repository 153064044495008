export const pieChartDefault = {
    options: {
        responsive: true,
        cutoutPercentage: 30,
        legend: {
            display: false
        },
        tooltips: {
            enabled: true
        }
    }
}

export default pieChartDefault;