import 'babel-polyfill'
import Chart from 'chart.js';
import Vue from 'vue/dist/vue.js'
import VueI18n from 'vue-i18n'
import translation from '../messages'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: current_language,
    messages: translation,
})

import histogramDefaults from './chart_histogram_defaults.js';

if(document.getElementById("chart_BIDDERS_COUNT")) {
    var app = new Vue({
        i18n,
        delimiters: ['[[', ']]'],
        el: '#chart_BIDDERS_COUNT_app',
        data: {
            defaults: histogramDefaults,
            chartData: null,
        },
        methods: {
            createChart(chartId) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.defaults.options,
                });
            },
            tooltipTitle (tooltipItem, data) {
                return this.$t("histogram.xAxisTooltip") + data['labels'][tooltipItem[0]['index']];
            },
            tooltipLabel (tooltipItem, data) {
                return this.$t("histogram.yAxisTooltip") + this.$n(data['datasets'][0]['data'][tooltipItem['index']]);
            },
            tooltipFooter (tooltipItem, data) {
                return "";
            },
        },
        mounted: function() {
            this.chartData = biddersCountData;
            this.defaults.options.scales.xAxes[0].scaleLabel.labelString = this.$t("histogram.xAxisLabel");
            this.defaults.options.scales.yAxes[0].scaleLabel.labelString = this.$t("histogram.yAxisLabel");
            this.defaults.options.tooltips.callbacks = {};
            this.defaults.options.tooltips.callbacks.title = this.tooltipTitle;
            this.defaults.options.tooltips.callbacks.label = this.tooltipLabel;
            this.defaults.options.tooltips.callbacks.footer = this.tooltipFooter;
            this.createChart('chart_BIDDERS_COUNT');
        }
    })
}

if(document.getElementById("popup_BIDDERS_COUNT_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#popup_BIDDERS_COUNT_app',
        data: {
            showRawData: false,
        },
        methods: {
            toggleRawData: function() {
                this.showRawData = !this.showRawData;
            }
        }
    })
}