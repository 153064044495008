import Vue from 'vue/dist/vue.js'

Vue.component("popup", {
    delimiters: ['[[', ']]'],
    template: '#popup_template',
    data: function() {
        return {};
    },
    beforeCreate: function() {
        document.body.classList.add("no-scroll");
    },
    methods: {
        close: function() {
            document.body.classList.remove("no-scroll");
            this.$emit('close');
        }
    }
})