var mixin = {
    methods: {
        tooltipTitle(tooltipItem, data) {
            var words = (data['labels'][tooltipItem[0]['index']]).split(" ");
            var result = []
            var line = []
            for (var word in words) {
                line.push(words[word]);
                var lineLength = line.join(" ").length;

                if (lineLength > 20) {
                    result.push(line.join(" "));
                    line = [];
                }
            }

            if (line.length > 0) {
                result.push(line.join(" "));
            }

            return result;
        },
        tooltipLabel(tooltipItem, data) {
            return " " + this.$n(data['datasets'][0]['data'][tooltipItem['index']]);
        },
    },
}

export default mixin