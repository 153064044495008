import 'babel-polyfill'

import Vue from 'vue/dist/vue.js'

if(document.getElementById("popup_BUYER_PROFILE_PUBLISHING_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#popup_BUYER_PROFILE_PUBLISHING_app',
        data: {
            showRawData: false,
        },
        methods: {
            toggleRawData: function() {
                this.showRawData = !this.showRawData;
            }
        }
    })
}