import 'babel-polyfill';
import axios from 'axios';
import Chart from 'chart.js';
import Multiselect from 'vue-multiselect';
import Vue from 'vue/dist/vue.js'

Vue.component('multiselect', Multiselect)

var popup = require('./rating/popup.js')
var overallChart = require('./rating/overall.js');
var winnersConcentrationChart = require('./rating/winners_concentration.js');
var biddersCountChart = require('./rating/bidders_count.js');
var shareOnSpendingsChart = require('./rating/share_on_spendings.js');
var opennessChart = require('./rating/openness.js');
var competitionSupport = require('./rating/competition_support.js');
var nontransparentOrIncorrectInfo = require('./rating/nontransparent_or_incorrect_info.js');
var confusingBehavior = require('./rating/confusing_behavior.js');
var buyerProfilePublishing = require('./rating/buyer_profile_publishing.js');
var supplierRating = require('./rating/supplier_rating.js');


if (document.getElementById("actual_row")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#actual_row',
        data: {
            showMore: false,
        },
        methods: {
            goTo: function (path) {
                window.location.href = path;
            }
        }
    })
}


if (document.getElementById("action_row")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#action_row',
        data: {
            showSubscriptions: false,
        },
    })
}


if (document.getElementById("change_lang_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#change_lang_app',
        data: {
            current_language: null,
            language: null,
            next: null
        },
        methods: {
            changeLang: function (lang) {
                this.language = lang;
                this.current_language = current_language;
                this.next = window.location.pathname;
                // properties setting above causes DOM changes so it is necessary to wait until VUE performs
                // DOM update before sending the form
                this.$nextTick(() => {
                    this.$refs.form.submit();
                })
            }
        }
    })
}

if (document.getElementById("submitter_selector")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#submitter_selector',
        data: {
            value: '',
            options: [],
            isSelectorLoading: false

        },
        methods: {
            searchSubmitters: function (query) {
                this.isSelectorLoading = true;
                axios.get('rest/autocomplete_submitters/' + query)
                    .then(response => {
                        this.options = response.data;
                        this.isSelectorLoading = false;
                        console.log("Response with submitters received");
                    })
                    .catch(e => {
                        this.options = [];
                        this.isSelectorLoading = false;
                        // 404 occurs if the search term is empty (its'n error state in this case). Other error statuses are logged.
                        if (e.response.status != 404) {
                            console.log(e)
                        }
                    });
            },
            selectedSubmitter: function (selected, id) {
                var url = "/submitter/" + selected.submitter_id + "/period/" + selected.period_id + "/" + selected.name_slug + "/" + selected.period_name;
                window.location.href = url;
            }
        }
    })
}

if (document.getElementById("carousel_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#carousel_app',
        data: {
            timer: null,
        },
        created: function () {
            this.timer = setInterval(this.next, 3000)
        },
        methods: {
            slidePrevious() {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.previous();
            },
            previous() {
                var slot_1 = this.$refs.slot_1;
                var slot_2 = this.$refs.slot_2;
                var slot_3 = this.$refs.slot_3;

                var content_1 = slot_1.children[0];
                var content_2 = slot_2.children[0];
                var content_3 = slot_3.children[0];

                content_1.parentNode.removeChild(content_1);
                slot_3.appendChild(content_1);

                content_3.parentNode.removeChild(content_3);
                slot_2.appendChild(content_3);

                content_2.parentNode.removeChild(content_2);
                slot_1.appendChild(content_2);

            },
            slideNext() {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.next();
            },
            next() {
                var slot_1 = this.$refs.slot_1;
                var slot_2 = this.$refs.slot_2;
                var slot_3 = this.$refs.slot_3;

                var content_1 = slot_1.children[0];
                var content_2 = slot_2.children[0];
                var content_3 = slot_3.children[0];

                content_1.parentNode.removeChild(content_1);
                slot_2.appendChild(content_1);

                content_3.parentNode.removeChild(content_3);
                slot_1.appendChild(content_3);

                content_2.parentNode.removeChild(content_2);
                slot_3.appendChild(content_2);
            }
        }
    })
}

if (document.getElementById("rating_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#rating_app',
        data: {
            showAvg: false
        },
    })
}


if (document.getElementById("feedback_app")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#feedback_app',
        data: {
            showEditor: false,
            feedbackContent: '',
            editor: null
        },
        mounted: function () {
            var toolbarOptions = [
                [{
                    'header': [1, 2, 3, 4, 5, 6, false]
                }],

                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{
                    'list': 'ordered'
                }, {
                    'list': 'bullet'
                }],

                [{
                    'indent': '-1'
                }, {
                    'indent': '+1'
                }], // outdent/indent

                [{
                    'align': []
                }],

                ['clean'] // remove formatting button
            ];

            var options = {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: "snow"
            };

            this.editor = new Quill(this.$refs.feedback_editor, options);
        },
        methods: {
            toggleEditor: function () {
                this.showEditor = !this.showEditor
            },
            saveFeedback: function () {
                this.feedbackContent = this.editor.root.innerHTML;
                console.log("Content" + this.feedbackContent);
                this.$refs.feedbackHolder.value = this.feedbackContent;
                this.$refs.feedback_form.submit();
            }
        },
    })
}