import 'babel-polyfill'
import Chart from 'chart.js';

import Vue from 'vue/dist/vue.js'

import pieDefaults from './chart_pie_defaults.js';

if(document.getElementById("chart_OVERALL")) {
    var app = new Vue({
        delimiters: ['[[', ']]'],
        el: '#chart_OVERALL_app',
        data: {
            defaults: pieDefaults,
            chartData: null,
        },
        methods: {
            createChart(chartId) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.defaults.options,
                });
            }
        },
        mounted: function() {
            this.chartData = overallData;
            this.createChart('chart_OVERALL');
        }
    })
}