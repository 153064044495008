export const histogramChartDefault = {
    options: {
        responsive: true,
        legend: {
            display: false
        },
        tooltips: {
            enabled: true,
            displayColors: false
        },
        scales: {
            xAxes: [{
                barPercentage: 1.2,
                scaleLabel: {
                    display: true,
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                }
            }]
        }
    }
}

export default histogramChartDefault;