const messages = {
    cs: {
        "histogram": {
            "xAxisLabel": "počet nabídek",
            "yAxisLabel": "počet zakázek",
            "xAxisTooltip": "počet nabídek: ",
            "yAxisTooltip": "počet zakázek: ",
        }
    },
    en: {
        "histogram": {
            "xAxisLabel": "offers count",
            "yAxisLabel": "contracts count",
            "xAxisTooltip": "offers count: ",
            "yAxisTooltip": "contracts count: ",
        }
    },
    sk: {
        "histogram": {
            "xAxisLabel": "počet ponúk",
            "yAxisLabel": "počet súťaží",
            "xAxisTooltip": "počet ponúk: ",
            "yAxisTooltip": "počet súťaží: ",
        }
    }
}

export default messages